export enum ELocale {
  EN_US = 'en-US',
  DE_DE = 'de-DE',
  EN_GB = 'en-GB',
  FR_FR = 'fr-FR',
  IT_IT = 'it-IT',
  PL_PL = 'pl-PL',
  CS_CZ = 'cs-CZ',
  SK_SK = 'sk-SK',
  NL_NL = 'nl-NL',
  NN_NO = 'nn-NO',
  ES_ES = 'es-ES',
  SV_SE = 'sv-SE',
  DA_DK = 'da-DK',
}
