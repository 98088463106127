import { OTContextProvider } from 'onetrust/useOTStatus';
import { GPTScriptsWaitForOneTrust } from 'googlead/components/GPTScripts';
import { GTMIframe } from 'gtm/GTMHeader';
import { FPCHeaderWaitForOneTrust, FPCNoScript } from 'util/facebookAnalytics';
import { DoubleClickPixel } from 'doubleclickPixel/DoubleClickPixel';
import { UnitSelectorProvider } from 'components/UnitSwitch';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import React from 'react';
import DefaultLayout from 'root/defaultLayout';
import queryClient from 'root/queryClient';
import { SettingsContext } from 'settings/SettingsContext';
import { IResortLayout } from 'page-components/resort/_layout';
import { RouteAndAnalyticsProps } from 'page-components/pathTypes';
import { IRegionLayout } from 'page-components/region/_layout';
import {
  AnalyticsContext,
  IAnalyticsContext,
  setPageVariables,
} from 'util/analytics';
import { isWidgetPageGuard } from 'components/widget/utils';
import NextTopLoader from 'nextjs-toploader';

function isResortProps(props: unknown): props is IResortLayout {
  return (
    !!(props as IResortLayout).pathInfo?.title_original
    && !!(props as IResortLayout).pathInfo?.region_en
    && !!(props as IResortLayout).pathInfo?.country_code
    && (props as RouteAndAnalyticsProps).type === 'resort'
  );
}

function isRegionProps(props: unknown): props is IRegionLayout {
  return (
    !!(props as IRegionLayout).pathInfo?.title_original
    && !!(props as IRegionLayout).pathInfo?.country_code
    && (props as RouteAndAnalyticsProps).type === 'region'
  );
}

function MyApp({
  Component,
  pageProps,
}: {
  Component: any;
  pageProps: RouteAndAnalyticsProps;
}) {
  const Layout = Component.Layout || DefaultLayout;

  const analyticsProps: IAnalyticsContext = {
    templateName: pageProps.templateName,
    templateId: pageProps.templateId,
  };

  if (isResortProps(pageProps)) {
    analyticsProps.resortEnName = pageProps.pathInfo.title_original;
    analyticsProps.resortId = parseInt(pageProps.pathInfo.uuid, 10);
    analyticsProps.country = pageProps.pathInfo.country_code;
    analyticsProps.regionEnName = pageProps.pathInfo.region_en;
    analyticsProps.regionId = parseInt(pageProps.pathInfo.region_id, 10);
  }

  if (isRegionProps(pageProps)) {
    analyticsProps.regionEnName = pageProps.pathInfo.title_original;
    analyticsProps.regionId = parseInt(pageProps.pathInfo.uuid, 10);
    analyticsProps.country = pageProps.pathInfo.country_code;
  }

  setPageVariables(analyticsProps);
  const isWidget = isWidgetPageGuard(pageProps) && pageProps.isWidgetPage;

  if (isWidget) {
    return (
      <SettingsContext.Provider value={pageProps}>
        <QueryClientProvider client={queryClient}>
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SettingsContext.Provider>
    );
  }
  if (!Component) return null;

  return (
    <SettingsContext.Provider value={pageProps}>
      <AnalyticsContext {...analyticsProps}>
        <OTContextProvider>
          <GTMIframe />
          <GPTScriptsWaitForOneTrust />
          <FPCNoScript />
          <FPCHeaderWaitForOneTrust />
          <DoubleClickPixel />
          <UnitSelectorProvider>
            <QueryClientProvider client={queryClient}>
              { /** <TranslationUpdater /> */}
              <Layout {...pageProps}>
                <NextTopLoader showSpinner={false} />
                <Component {...pageProps} />
              </Layout>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </UnitSelectorProvider>
        </OTContextProvider>
      </AnalyticsContext>
    </SettingsContext.Provider>
  );
}

export default MyApp;
