/* eslint-disable no-console */

// TODO: const LOG_LOCALLY = IS_DEVELOP;
const LOG_LOCALLY = true;
// const LOG_REMOTELY = true;

export type ErrorLike = {
  /**
   * Error will be tagged with this
   */
  tag?: string;
  /**
   * Logger will dump message in console before error as breadcrumb
   */
  message?: string;
  /**
   * Error to log
   */
  error: Error;
  /**
   * Arbitrary data to serialize and log
   */
  data?: any;
};

export type MessageLike = {
  /**
   * Error will be tagged with this
   */
  tag?: string;
  /**
   * Logger will dump message in console before error as breadcrumb
   */
  message?: string;
  /**
   * Arbitrary data to serialize and log
   */
  data?: any;
};

export const logError = async ({
  tag, message, error, data,
}: ErrorLike) => {
  if (LOG_LOCALLY) {
    if (tag || message || data) {
      const arr = [];
      if (tag) {
        arr.push(`[${tag}]`);
      }
      if (message) {
        arr.push(message);
      }
      if (typeof data !== 'undefined') {
        arr.push(data);
      }
      console.log(...arr);
    }
    console.error(error);
  }
};

export const logMessage = async ({ tag, message, data }: MessageLike) => {
  if (LOG_LOCALLY) {
    if (tag || message || data) {
      const arr = [];
      if (tag) {
        arr.push(`[${tag}]`);
      }
      if (message) {
        arr.push(message);
      }
      if (typeof data !== 'undefined') {
        arr.push(data);
      }
      console.log(...arr);
    }
  }
};

export const Diagnostics = {
  /**
   * Use for most important messages
   */
  error: (props: Partial<ErrorLike>) => {
    if (!props.error && props.message) {
      // eslint-disable-next-line no-param-reassign
      props.error = new Error(props.message);
    }
    logError(props as ErrorLike);
  },

  /**
   * Use for messages useful for crash dumps
   * @param props
   */
  message: (props: MessageLike) => {
    logMessage(props);
  },

  /**
   * Use for least critical data, assume this can never reach crash dumps
   * @param args
   */
  debug: (...args: any[]) => console.log(...args), // TODO: More "just log" methods
};
