import React, { useContext } from 'react';
import { DistanceType, TempType } from 'i18n/types';
import { getDefaultTemperatureUnit, getDefaultDistanceUnit } from 'i18n/locale';

export interface UnitOptions {
  temperature: TempType;
  distance: DistanceType;
  setDistanceType?: (dt: DistanceType) => void;
  setTemperatureType?: (tt: TempType) => void;
}

export const UnitContext = React.createContext<UnitOptions>({
  temperature: getDefaultTemperatureUnit(),
  distance: getDefaultDistanceUnit(),
});

export const useUnitContext = () => useContext(UnitContext);
export const useDistanceUnit = () => {
  const { distance } = useUnitContext();
  return distance;
};

export const useTemperatureUnit = () => {
  const { temperature } = useUnitContext();
  return temperature;
};
