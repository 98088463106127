import { IRegionShort } from 'api/region/types';
import {
  IElevation, IOpenStat, IResortPathInfo, ISnowStatus, ISnowfallItem,
} from 'api/resort/types';

export type Nullable<T> = T | null;

export type WidgetFormFields = 'widgetType' | 'searchData' | 'widgetLanguage' | 'widgetWidth';

export type GlobalPropsWithIsWidgetFlag = {
  isWidgetPage: boolean
};

export type WidgetRenderItem = IResortPathInfo | IRegionShort;

export type GeneralWidgetPageProps = {
  type: string,
  route: string,
  templateName?: string,
  templateId?: string,
  isWidgetPage:boolean
};

export enum WidgetType {
  SINGLE_RESORT = 'resort',
  LIST_OF_RESORTS = 'resorts',
  SINGLE_REGION = 'regionId',
}

export type WidgetResort = Omit<IResortPathInfo, 'hasSkiRental'>;
export type WidgetResortData = {
  lifts: {
    total: number | null,
    open: number | null,
  },
  runs: {
    total: number | null,
    open: number | null,
  },
  elevation: IElevation,
  depths: {
    base: number,
    middle: number,
    summit: number,
  },
  status: IOpenStat,
  snowfall: ISnowfallItem,
  snow: ISnowStatus,
  resort: WidgetResort
  updatedAt: string
};

export type WidgetRegionData = { resorts: WidgetResortData[], region: IRegionShort, };

export type IframeMessage = { value: number };

export type IframeEventMessage = string;
