import { useEffect } from 'react';
import { IS_SSR } from 'ots-constants';

export const DoubleClickPixel = () => {
  useEffect(() => {
    if (!IS_SSR) {
      const a = String(Math.floor(Math.random() * 10000000000000000));
      new Image().src = `https://pubads.g.doubleclick.net/activity;dc_iu=/7730/DFPAudiencePixel;ord=${a};dc_seg=7264807841?`;
    }
  }, []);
  return (
    <noscript>
      <img
        style={{ position: 'absolute' }}
        alt=""
        src="https://pubads.g.doubleclick.net/activity;dc_iu=/7730/DFPAudiencePixel;ord=1;dc_seg=7264807841?"
        width={1}
        height={1}
      />
    </noscript>
  );
};
