import { OpenFlag, SurfaceType } from 'api/resort/types';
import { I18nKey } from 'i18n/types';
import { TOptions, StringMap } from 'i18next';
import { useTranslation } from 'next-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import Mustache from 'mustache';
import { IS_SSR } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';

// eslint-disable-next-line func-names
Mustache.escape = function (text) { return text; };

export type CommonKeyTranslationFuncProps = (key: I18nKey['common'], options?: TOptions<StringMap> | string) => string;

export const useOTSTranslation = <NS extends keyof I18nKey>(ns: NS) => useTranslation(ns) as Omit<UseTranslationResponse<NS>, 't'> & {
  t: (key: I18nKey[NS], options?: TOptions<StringMap> | string) => string;
  // Returns strictly string as we know what kind of keys and result we have for this because we defined that like so
};

export const useMustacheTranslation = <NS extends keyof I18nKey>(ns: NS) => {
  const { t } = useOTSTranslation(ns);
  const newT = (key: I18nKey[NS], variables: Record<string, unknown>, options?: TOptions<StringMap>) => {
    const value = t(key, {
      ...options,
      interpolation: {
        skipOnVariables: true,
      },
    });
    const vars = { ...variables };
    Object.keys(variables).forEach((k) => {
      if (variables[k]) {
        vars[`if_${k}`] = { _: variables[k], ...variables };
      }
    });
    try {
      return Mustache.render(value, vars);
    } catch (error) {
      if (IS_SSR) {
        Diagnostics.error({ tag: 'i18n', error: error as Error, message: 'Mustache template has errors' });
      } else {
        console.error(error);
      }
      return '';
    }
  };
  return newT;
};

export const openFlagMap: Record<OpenFlag, I18nKey['common']> = {
  [OpenFlag.Closed]: 'resort.skiReport.closed',
  [OpenFlag.TemporarilyClosed]: 'resort.skiReport.temporarilyClosed',
  [OpenFlag.PermanentlyClosed]: 'resort.skiReport.permanentlyClosed',
  [OpenFlag.NoReportAvailable]: 'resort.skiReport.noReportAvailable',
  [OpenFlag.WeekendsOnly]: 'resort.skiReport.weekendsOnly',
  [OpenFlag.Open]: 'resort.skiReport.open',
};

export const useOpenFlagTranslation = () => {
  const { t } = useOTSTranslation('common');
  return (key?: OpenFlag, fallbackKey?: I18nKey['common']) => (key ? t(openFlagMap[key]) : undefined) || (fallbackKey ? (t(fallbackKey)) : undefined);
};

export const surfaceTypeMap: Record<SurfaceType, I18nKey['common']> = {
  [SurfaceType.HardPacked]: 'resort.skiReport.surfaceHardPacked',
  [SurfaceType.BareSpots]: 'resort.skiReport.surfaceBareSpots' as any, // MISSING
  [SurfaceType.CornSnow]: 'resort.skiReport.surfaceCornSnow',
  [SurfaceType.FrozenGranular]: 'resort.skiReport.surfaceFrozenGranular' as any, // MISSING
  [SurfaceType.Granular]: 'resort.skiReport.surfaceGranular' as any, // MISSING
  [SurfaceType.LooseGranular]: 'resort.skiReport.surfaceLooseGranular' as any, // MISSING
  [SurfaceType.VariableConditions]: 'resort.skiReport.surfaceVariableConditions',
  [SurfaceType.MachineGroomed]: 'resort.skiReport.surfaceMachineGroomed',
  [SurfaceType.MachineMade]: 'resort.skiReport.surfaceMachineMade',
  [SurfaceType.PackedPowder]: 'resort.skiReport.surfacePackedPowder',
  [SurfaceType.Powder]: 'resort.skiReport.surfacePowder',
  [SurfaceType.SpringSnow]: 'resort.skiReport.surfaceSpringSnow',
  [SurfaceType.WetSnow]: 'resort.skiReport.surfaceWetSnow',
  [SurfaceType.Obstacles]: 'resort.skiReport.surfaceObstacles' as any, // MISSING
  [SurfaceType.WindBlown]: 'resort.skiReport.surfaceWindBlown' as any, // MISSING
  [SurfaceType.ThinCover]: 'resort.skiReport.surfaceThinCover' as any, // MISSING
  [SurfaceType.SkiersPacked]: 'resort.skiReport.surfaceSkiersPacked' as any, // MISSING
  [SurfaceType.IcySpots]: 'resort.skiReport.surfaceIcySpots' as any, // MISSING
};

export const useSurfaceTypeTranslation = () => {
  const { t } = useOTSTranslation('common');
  return (key?: SurfaceType, fallbackKey?: I18nKey['common']) => (key ? t(surfaceTypeMap[key]) : undefined) || (fallbackKey ? (t(fallbackKey)) : undefined);
};
