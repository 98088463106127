import { ELocale } from 'i18n/localeEnum';

export const ONETRUST_COOKIE = 'OptanonConsent';

export const ONETRUST_PREFIX = 'optanon-category-';

export enum OneTrustCategories {
  /**
   * Strictly Necessary Cookies
   */
  StrictlyNecessaryCookies = 'C0001',
  /**
   * Performance Cookies
   */
  PerformanceCookies = 'C0002',
  /**
   * Functional Cookies
   */
  FunctionalCookies = 'C0003',
  /**
   * Targeting Cookies
   */
  TargetingCookies = 'C0004',
  /**
   * Social Media Cookies
   */
  SocialMediaCookies = 'C0005',
}

export const ONE_TRUST_CONFIG: Record<ELocale, {
  prodv2: string,
  v: string;
  devv2: string,
  prod: string,
  dev: string,
  pigeon: string,
  pSettings: string,
  pReject: string,
}> = {
  [ELocale.CS_CZ]: {
    v: 'iab2',
    prodv2: 'a20b0c5e-50be-410a-8c29-69cf6e716881',
    devv2: 'a20b0c5e-50be-410a-8c29-69cf6e716881-test',
    prod: 'https://cdn.cookielaw.org/langswitch/1c48a030-789e-4f0e-9445-eab9a74d8cf9.js',
    dev: 'https://optanon.blob.core.windows.net/consent/70597955-d3c2-4b5e-8da2-e827e53daac4-test.js',
    pigeon: 'moje.onthesnow.cz',
    pSettings: 'https://moje.onthesnow.cz/user/',
    pReject: 'https://www.onthesnow.cz/news/ochrana-soukromi/',
  },
  [ELocale.SK_SK]: {
    v: 'iab2',
    prodv2: '6db7ce2e-b00a-40ee-9f87-a9ce9977b9c9',
    devv2: '6db7ce2e-b00a-40ee-9f87-a9ce9977b9c9-test',
    prod: 'https://cdn.cookielaw.org/langswitch/bd9bfc3c-5a08-4b67-97f0-895578066b8f.js',
    dev: 'https://optanon.blob.core.windows.net/consent/a6cd5788-dce6-46a0-8be9-47e0cb4f547d-test.js',
    pigeon: 'moje.onthesnow.sk',
    pSettings: 'https://moje.onthesnow.sk/user/',
    pReject: 'https://www.onthesnow.sk/news/ochrana-sukromia/',
  },
  [ELocale.DE_DE]: {
    v: 'iab2',
    prodv2: '1ff67bc2-d4e2-4bc2-88b9-9e652ebbf314',
    devv2: '1ff67bc2-d4e2-4bc2-88b9-9e652ebbf314-test',
    prod: 'https://cdn.cookielaw.org/langswitch/c2f6cc26-432c-43dd-be45-aa60b36310e5.js',
    dev: 'https://optanon.blob.core.windows.net/consent/47e05c00-52cc-416b-adc9-4698b70a1f60-test.js',
    pigeon: 'mein.skiinfo.de',
    pSettings: 'https://mein.skiinfo.de/user/',
    pReject: 'https://www.skiinfo.de/news/datenschutz/',
  },
  [ELocale.IT_IT]: {
    v: 'iab2',
    prodv2: '0c5bcd1e-465d-4d41-b515-14528fa9fa67',
    devv2: '0c5bcd1e-465d-4d41-b515-14528fa9fa67-test',
    prod: 'https://cdn.cookielaw.org/langswitch/3e61e827-cf27-47d9-a185-60addb71715e.js',
    dev: 'https://optanon.blob.core.windows.net/consent/3fedbb3e-3cbc-4c5f-a8ae-5a1ea5ef8788-test.js',
    pigeon: 'mio.skiinfo.it',
    pSettings: 'https://mio.skiinfo.it/user/',
    pReject: 'https://www.skiinfo.it/news/privacy/',
  },
  [ELocale.EN_GB]: {
    v: 'iab2',
    prodv2: '6fa82a05-7aa2-4eed-bf55-32c34b4ded49',
    devv2: '6fa82a05-7aa2-4eed-bf55-32c34b4ded49-test',
    prod: 'https://cdn.cookielaw.org/langswitch/e83393c5-ab2c-411e-8ff9-666c9369932f.js',
    dev: 'https://optanon.blob.core.windows.net/consent/c9733332-84ea-4272-823d-07833180337c-test.js',
    pigeon: 'my.onthesnow.co.uk',
    pSettings: 'https://my.onthesnow.co.uk/user/',
    pReject: 'https://www.onthesnow.co.uk/news/privacy/',
  },
  [ELocale.DA_DK]: {
    v: 'iab2',
    prodv2: '9f761489-2fec-451c-af0d-107df5674dc1',
    devv2: '9f761489-2fec-451c-af0d-107df5674dc1-test',
    prod: 'https://cdn.cookielaw.org/langswitch/51f2d9c1-2994-4507-b4ed-a2e44c9ed4b3.js',
    dev: 'https://optanon.blob.core.windows.net/consent/220681fe-c6dc-409a-97df-2cb6a6e4c71c-test.js',
    pigeon: 'min.skiinfo.dk',
    pSettings: 'https://min.skiinfo.dk/user/',
    pReject: 'https://www.skiinfo.dk/news/privatlivspolitik/',
  },
  [ELocale.ES_ES]: {
    v: 'iab2',
    prodv2: 'f5e5fa18-a318-43dd-952b-ca582676ffc6',
    devv2: 'f5e5fa18-a318-43dd-952b-ca582676ffc6-test',
    prod: 'https://cdn.cookielaw.org/langswitch/8c5d76e1-b30d-43e1-a1a0-67e0b75e5452.js',
    dev: 'https://optanon.blob.core.windows.net/consent/9c1f1782-b3de-48d4-ab21-313929b85200-test.js',
    pigeon: 'mi.es.skiinfo.com',
    pSettings: 'https://mi.es.skiinfo.com/user/',
    pReject: 'https://es.skiinfo.com/news/privacidad/',
  },
  [ELocale.FR_FR]: {
    v: 'iab2',
    prodv2: '5615e4f8-178e-4482-a4e5-c1d9312c9ae1',
    devv2: '5615e4f8-178e-4482-a4e5-c1d9312c9ae1-test',
    prod: 'https://cdn.cookielaw.org/langswitch/40859502-4b22-4dfe-80c2-4e745b1c554a.js',
    dev: 'https://optanon.blob.core.windows.net/consent/edc40f20-fbe4-4925-8eac-fd8ca70561fa-test.js',
    pigeon: 'mon.skiinfo.fr',
    pSettings: 'https://mon.skiinfo.fr/user/',
    pReject: 'https://www.skiinfo.fr/news/vie-privee/',
  },
  [ELocale.NL_NL]: {
    v: 'iab2',
    prodv2: 'ab930af7-cb57-4d79-b539-00df0818406f',
    devv2: 'ab930af7-cb57-4d79-b539-00df0818406f-test',
    prod: 'https://cdn.cookielaw.org/langswitch/2a08f26a-4afd-4d09-858b-02fee0299fc7.js',
    dev: 'https://optanon.blob.core.windows.net/consent/152bf7de-09f0-4ed0-927e-1ffd9f5840c7-test.js',
    pigeon: 'mijn.skiinfo.nl',
    pSettings: 'https://mijn.skiinfo.nl/user/',
    pReject: 'https://www.skiinfo.nl/news/privacy/',
  },
  [ELocale.NN_NO]: {
    v: 'iab2',
    prodv2: '65a83576-af6b-414d-9f8f-93a0727a55dc',
    devv2: '65a83576-af6b-414d-9f8f-93a0727a55dc-test',
    prod: 'https://cdn.cookielaw.org/langswitch/32ac0e5d-e412-49f0-8fa9-d001e122b507.js',
    dev: 'https://optanon.blob.core.windows.net/consent/492ac226-e9df-48e6-a210-24b75bc64fea-test.js',
    pigeon: 'min.skiinfo.no',
    pSettings: 'https://min.skiinfo.no/user/',
    pReject: 'https://www.skiinfo.no/news/personvernpolicy/',
  },
  [ELocale.PL_PL]: {
    v: 'iab2',
    prodv2: '36fee4a7-ffa7-41de-8487-0c00e91b35ad',
    devv2: '36fee4a7-ffa7-41de-8487-0c00e91b35ad-test',
    prod: 'https://cdn.cookielaw.org/langswitch/609729d4-33ee-470b-8db5-7b5d094ab207.js',
    dev: 'https://optanon.blob.core.windows.net/consent/b669bbc2-f12d-44b8-ab13-550671eeda79-test.js',
    pigeon: 'moje.skiinfo.pl',
    pSettings: 'https://moje.skiinfo.pl/user/',
    pReject: 'https://www.skiinfo.pl/news/polityka-prywatnosci/',
  },
  [ELocale.SV_SE]: {
    v: 'iab2',
    prodv2: 'e1f467dd-c9f4-4be2-b0d3-4cabad618ce2',
    devv2: 'e1f467dd-c9f4-4be2-b0d3-4cabad618ce2-test',
    prod: 'https://cdn.cookielaw.org/langswitch/703a3ce5-f6e5-44b6-805c-d9b945067ed3.js',
    dev: 'https://optanon.blob.core.windows.net/consent/9737f7e3-64c2-4840-9c01-89a9b0157f7c-test.js',
    pigeon: 'min.skiinfo.se',
    pSettings: 'https://min.skiinfo.se/user/',
    pReject: 'https://www.skiinfo.se/news/dataskyddspolicy/',
  },
  [ELocale.EN_US]: {
    v: 'ccpa',
    prodv2: 'f87bd5d6-e49e-498a-b381-7adc5f356f0a',
    devv2: 'f87bd5d6-e49e-498a-b381-7adc5f356f0a-test',
    prod: 'https://cdn.cookielaw.org/langswitch/3b3c711e-2f68-4d2e-8364-ead9712e1c5c.js',
    dev: 'https://optanon.blob.core.windows.net/consent/1004f70b-b44d-486e-bea7-726e0a6699cf-test.js',
    pigeon: '',
    pSettings: '',
    pReject: '',
  },
};
