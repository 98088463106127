import { useEffect } from 'react';

import type { IOneTrustCookie } from 'onetrust/types';
import { useOTStatus } from 'onetrust/useOTStatus';
import { useOptAnonWrapper } from 'util/useOptAnonWrapper';

type CallbackType = (oneTrust: typeof OneTrust, oneTrustCookie?: IOneTrustCookie) => void;

export const useOneTrustInsertAvailableByStatus = (onOneTrustReady?: CallbackType) => {
  const status = useOTStatus();
  const oneTrust = useOptAnonWrapper();

  useEffect(() => {
    if (!!oneTrust && typeof oneTrust !== 'undefined' && status.ready && onOneTrustReady) {
      // used to call callback each time status changed -
      // that means we update list of available for loading scripts types
      onOneTrustReady(OneTrust, status as IOneTrustCookie);
    }
  }, [status, onOneTrustReady, oneTrust]);

  return { oneTrust, status };
};
