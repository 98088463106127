import 'util/initI18nDates';

import '../../styles/globals.scss';
import MyApp from 'root/App';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config';

console.log(nextI18NextConfig);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(MyApp, nextI18NextConfig);
