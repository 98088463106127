// require('./init-env');
const HttpApi = require('i18next-http-backend/cjs');

if (!process.env.NEXT_PUBLIC_LANG) {
  throw new Error('Please specify NEXT_PUBLIC_LANG to build for in NEXT_PUBLIC_LANG .env variable');
}

const isBrowser = typeof window !== 'undefined';

module.exports = {
  react: {
    useSuspense: false,
  },
  i18n: {
    defaultLocale: 'en',
    locales: ['en', process.env.NEXT_PUBLIC_LANG],
    backend: !isBrowser ? {
      // expirationTime: 5 * 1000, // 5 seconds
      // reloadInterval: 5 * 1000, // 5 seconds
      reloadInterval: 30 * 60 * 1000, // 30 mins
      loadPath: `http://localhost:${process.env.NEXT_PUBLIC_MEMCACHE_PORT}/translations/{{ns}}`,
      addPath: '',
    } : undefined,
  },
  debug: false,
  ns: ['common', 'unit', 'seo'],
  interpolation: {
    skipOnVariables: false,
    escapeValue: false,
  },
  serializeConfig: false,
  use: isBrowser ? [] : [HttpApi],
};
