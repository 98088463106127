import { BUILD_LOCALE } from 'ots-constants';
import { defaultLocale } from 'i18n/constants';
import { DistanceType, TempType } from 'i18n/types';

const overridenLocale = (() => {
  const w = typeof window !== 'undefined' ? window : undefined;
  if (w && w.location && w.location.search) {
    const lang = w.location.search.split('locale=');
    if (lang.length === 2 && lang[1].length === 5) {
      return lang[1];
    }
  }
  return undefined;
})();

export const getLocale = () => overridenLocale || BUILD_LOCALE || defaultLocale;

export const getLang = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lng, cnt] = getLocale().split('-');
  return lng;
};

export const getCountry = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lng, cnt] = getLocale().split('-');
  return cnt;
};

export const getDefaultDistanceUnit = () => {
  const isUSA = getCountry() === 'US';
  return isUSA ? DistanceType.IMPERIAL : DistanceType.METRIC;
};

export const getDefaultTemperatureUnit = () => {
  const isUSA = getCountry() === 'US';
  return isUSA ? TempType.FAHRENHEIT : TempType.CELSIUS;
};

export const getUnderscoreLocale = () => getLocale().split('-').join('_');
