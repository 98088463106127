import { ELocale } from './i18n/localeEnum'; // Leave relative import here

export const ENV = process.env.NEXT_PUBLIC_ENV; // Dont import from constants

export type IDeployParams = {
  websiteDomain: string;
  wpDomain: string;
};

/**
 * NOPE: wpDomain should have slash in the end (!!!!!)
 */

export const localeLocalConfig: Record<ELocale, IDeployParams> = {
  [ELocale.EN_US]: {
    websiteDomain: 'https://ots-local.onthesnow.com',
    wpDomain: 'https://newsonthesnow.com/',
  },
  [ELocale.DE_DE]: {
    websiteDomain: 'https://ots-local.skiinfo.de',
    wpDomain: 'https://de.newsonthesnow.com/',
  },
  [ELocale.EN_GB]: {
    websiteDomain: 'https://ots-local.onthesnow.co.uk',
    wpDomain: 'https://uk.newsonthesnow.com/',
  },
  [ELocale.FR_FR]: {
    websiteDomain: 'https://ots-local.skiinfo.fr',
    wpDomain: 'https://fr.newsonthesnow.com/',
  },
  [ELocale.IT_IT]: {
    websiteDomain: 'https://ots-local.skiinfo.it',
    wpDomain: 'https://it.newsonthesnow.com/',
  },
  [ELocale.PL_PL]: {
    websiteDomain: 'https://ots-local.skiinfo.pl',
    wpDomain: 'https://pl.newsonthesnow.com/',
  },
  [ELocale.CS_CZ]: {
    websiteDomain: 'https://ots-local.onthesnow.cz',
    wpDomain: 'https://cz.newsonthesnow.com/',
  },
  [ELocale.SK_SK]: {
    websiteDomain: 'https://ots-local.onthesnow.sk',
    wpDomain: 'https://sk.newsonthesnow.com/',
  },
  [ELocale.NL_NL]: {
    websiteDomain: 'https://ots-local.skiinfo.nl',
    wpDomain: 'https://nl.newsonthesnow.com/',
  },
  [ELocale.NN_NO]: {
    websiteDomain: 'https://ots-local.skiinfo.no',
    wpDomain: 'https://no.newsonthesnow.com/',
  },
  [ELocale.ES_ES]: {
    websiteDomain: 'https://ots-local.es.skiinfo.com',
    wpDomain: 'https://es.newsonthesnow.com/',
  },
  [ELocale.SV_SE]: {
    websiteDomain: 'https://ots-local.skiinfo.se',
    wpDomain: 'https://se.newsonthesnow.com/',
  },
  [ELocale.DA_DK]: {
    websiteDomain: 'https://ots-local.skiinfo.dk',
    wpDomain: 'https://dk.newsonthesnow.com/',
  },
};

export const localeDevelopmentConfig: Record<ELocale, IDeployParams> = {
  [ELocale.EN_US]: {
    websiteDomain: 'https://ots-dev.onthesnow.com',
    wpDomain: 'https://newsonthesnow.com/',
  },
  [ELocale.DE_DE]: {
    websiteDomain: 'https://ots-dev.skiinfo.de',
    wpDomain: 'https://de.newsonthesnow.com/',
  },
  [ELocale.EN_GB]: {
    websiteDomain: 'https://ots-dev.onthesnow.co.uk',
    wpDomain: 'https://uk.newsonthesnow.com/',
  },
  [ELocale.FR_FR]: {
    websiteDomain: 'https://ots-dev.skiinfo.fr',
    wpDomain: 'https://fr.newsonthesnow.com/',
  },
  [ELocale.IT_IT]: {
    websiteDomain: 'https://ots-dev.skiinfo.it',
    wpDomain: 'https://it.newsonthesnow.com/',
  },
  [ELocale.PL_PL]: {
    websiteDomain: 'https://ots-dev.skiinfo.pl',
    wpDomain: 'https://pl.newsonthesnow.com/',
  },
  [ELocale.CS_CZ]: {
    websiteDomain: 'https://ots-dev.onthesnow.cz',
    wpDomain: 'https://cz.newsonthesnow.com/',
  },
  [ELocale.SK_SK]: {
    websiteDomain: 'https://ots-dev.onthesnow.sk',
    wpDomain: 'https://sk.newsonthesnow.com/',
  },
  [ELocale.NL_NL]: {
    websiteDomain: 'https://ots-dev.skiinfo.nl',
    wpDomain: 'https://nl.newsonthesnow.com/',
  },
  [ELocale.NN_NO]: {
    websiteDomain: 'https://ots-dev.skiinfo.no',
    wpDomain: 'https://no.newsonthesnow.com/',
  },
  [ELocale.ES_ES]: {
    websiteDomain: 'https://ots-dev.es.skiinfo.com',
    wpDomain: 'https://es.newsonthesnow.com/',
  },
  [ELocale.SV_SE]: {
    websiteDomain: 'https://ots-dev.skiinfo.se',
    wpDomain: 'https://se.newsonthesnow.com/',
  },
  [ELocale.DA_DK]: {
    websiteDomain: 'https://ots-dev.skiinfo.dk',
    wpDomain: 'https://dk.newsonthesnow.com/',
  },
};

/**
 * @deprecated
 */
export const localeStagingConfig: Record<ELocale, IDeployParams> = {
  [ELocale.EN_US]: {
    websiteDomain: 'https://ots-stage.onthesnow.com',
    wpDomain: 'https://newsonthesnow.com/',
  },
  [ELocale.DE_DE]: {
    websiteDomain: 'https://ots-stage.skiinfo.de',
    wpDomain: 'https://de.newsonthesnow.com/',
  },
  [ELocale.EN_GB]: {
    websiteDomain: 'https://ots-stage.onthesnow.co.uk',
    wpDomain: 'https://uk.newsonthesnow.com/',
  },
  [ELocale.FR_FR]: {
    websiteDomain: 'https://ots-stage.skiinfo.fr',
    wpDomain: 'https://fr.newsonthesnow.com/',
  },
  [ELocale.IT_IT]: {
    websiteDomain: 'https://ots-stage.skiinfo.it',
    wpDomain: 'https://it.newsonthesnow.com/',
  },
  [ELocale.PL_PL]: {
    websiteDomain: 'https://ots-stage.skiinfo.pl',
    wpDomain: 'https://pl.newsonthesnow.com/',
  },
  [ELocale.CS_CZ]: {
    websiteDomain: 'https://ots-stage.onthesnow.cz',
    wpDomain: 'https://cz.newsonthesnow.com/',
  },
  [ELocale.SK_SK]: {
    websiteDomain: 'https://ots-stage.onthesnow.sk',
    wpDomain: 'https://sk.newsonthesnow.com/',
  },
  [ELocale.NL_NL]: {
    websiteDomain: 'https://ots-stage.skiinfo.nl',
    wpDomain: 'https://nl.newsonthesnow.com/',
  },
  [ELocale.NN_NO]: {
    websiteDomain: 'https://ots-stage.skiinfo.no',
    wpDomain: 'https://no.newsonthesnow.com/',
  },
  [ELocale.ES_ES]: {
    websiteDomain: 'https://ots-stage.es.skiinfo.com',
    wpDomain: 'https://es.newsonthesnow.com/',
  },
  [ELocale.SV_SE]: {
    websiteDomain: 'https://ots-stage.skiinfo.se',
    wpDomain: 'https://se.newsonthesnow.com/',
  },
  [ELocale.DA_DK]: {
    websiteDomain: 'https://ots-stage.skiinfo.dk',
    wpDomain: 'https://dk.newsonthesnow.com/',
  },
};

export const localeStagingSSRConfig: Record<ELocale, IDeployParams> = {
  [ELocale.EN_US]: {
    websiteDomain: 'https://stage-ssr-us.onthesnow.com',
    wpDomain: 'https://newsonthesnow.com/',
  },
  [ELocale.DE_DE]: {
    websiteDomain: 'https://stage-ssr-de.skiinfo.de',
    wpDomain: 'https://de.newsonthesnow.com/',
  },
  [ELocale.EN_GB]: {
    websiteDomain: 'https://stage-ssr-uk.onthesnow.co.uk',
    wpDomain: 'https://uk.newsonthesnow.com/',
  },
  [ELocale.FR_FR]: {
    websiteDomain: 'https://stage-ssr-fr.skiinfo.fr',
    wpDomain: 'https://fr.newsonthesnow.com/',
  },
  [ELocale.IT_IT]: {
    websiteDomain: 'https://stage-ssr-it.skiinfo.it',
    wpDomain: 'https://it.newsonthesnow.com/',
  },
  [ELocale.PL_PL]: {
    websiteDomain: 'https://stage-ssr-pl.skiinfo.pl',
    wpDomain: 'https://pl.newsonthesnow.com/',
  },
  [ELocale.CS_CZ]: {
    websiteDomain: 'https://stage-ssr-cz.onthesnow.cz',
    wpDomain: 'https://cz.newsonthesnow.com/',
  },
  [ELocale.SK_SK]: {
    websiteDomain: 'https://stage-ssr-sk.onthesnow.sk',
    wpDomain: 'https://sk.newsonthesnow.com/',
  },
  [ELocale.NL_NL]: {
    websiteDomain: 'https://stage-ssr-nl.skiinfo.nl',
    wpDomain: 'https://nl.newsonthesnow.com/',
  },
  [ELocale.NN_NO]: {
    websiteDomain: 'https://stage-ssr-no.skiinfo.no',
    wpDomain: 'https://no.newsonthesnow.com/',
  },
  [ELocale.ES_ES]: {
    websiteDomain: 'https://stage-ssr-es.skiinfo.com',
    wpDomain: 'https://es.newsonthesnow.com/',
  },
  [ELocale.SV_SE]: {
    websiteDomain: 'https://stage-ssr-se.skiinfo.se',
    wpDomain: 'https://se.newsonthesnow.com/',
  },
  [ELocale.DA_DK]: {
    websiteDomain: 'https://stage-ssr-dk.skiinfo.dk',
    wpDomain: 'https://dk.newsonthesnow.com/',
  },
};

export const localeProductionConfig: Record<ELocale, IDeployParams> = {
  [ELocale.EN_US]: {
    websiteDomain: 'https://www.onthesnow.com',
    wpDomain: 'https://newsonthesnow.com/',
  },
  [ELocale.DE_DE]: {
    websiteDomain: 'https://www.skiinfo.de',
    wpDomain: 'https://de.newsonthesnow.com/',
  },
  [ELocale.EN_GB]: {
    websiteDomain: 'https://www.onthesnow.co.uk',
    wpDomain: 'https://uk.newsonthesnow.com/',
  },
  [ELocale.FR_FR]: {
    websiteDomain: 'https://www.skiinfo.fr',
    wpDomain: 'https://fr.newsonthesnow.com/',
  },
  [ELocale.IT_IT]: {
    websiteDomain: 'https://www.skiinfo.it',
    wpDomain: 'https://it.newsonthesnow.com/',
  },
  [ELocale.PL_PL]: {
    websiteDomain: 'https://www.skiinfo.pl',
    wpDomain: 'https://pl.newsonthesnow.com/',
  },
  [ELocale.CS_CZ]: {
    websiteDomain: 'https://www.onthesnow.cz',
    wpDomain: 'https://cz.newsonthesnow.com/',
  },
  [ELocale.SK_SK]: {
    websiteDomain: 'https://www.onthesnow.sk',
    wpDomain: 'https://sk.newsonthesnow.com/',
  },
  [ELocale.NL_NL]: {
    websiteDomain: 'https://www.skiinfo.nl',
    wpDomain: 'https://nl.newsonthesnow.com/',
  },
  [ELocale.NN_NO]: {
    websiteDomain: 'https://www.skiinfo.no',
    wpDomain: 'https://no.newsonthesnow.com/',
  },
  [ELocale.ES_ES]: {
    websiteDomain: 'https://es.skiinfo.com',
    wpDomain: 'https://es.newsonthesnow.com/',
  },
  [ELocale.SV_SE]: {
    websiteDomain: 'https://www.skiinfo.se',
    wpDomain: 'https://se.newsonthesnow.com/',
  },
  [ELocale.DA_DK]: {
    websiteDomain: 'https://www.skiinfo.dk',
    wpDomain: 'https://dk.newsonthesnow.com/',
  },
};

let lc = localeProductionConfig;

if (ENV === 'staging') {
  lc = localeStagingSSRConfig;
}

if (ENV === 'development') {
  lc = localeDevelopmentConfig;
}

if (ENV === 'local') {
  lc = localeLocalConfig;
}

export const localeConfig = lc;
