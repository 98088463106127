import { ELocale } from 'i18n/localeEnum';
import { options } from 'components/resort/HeaderLangSelect/HeaderLangSelect';
import { getLocale } from 'i18n/locale';

const locale = getLocale() as ELocale;
export const WIDGET_PAGE_PATH = 'widget';

export const extraSelectStyles = { borderColor: 'rgba(12, 113, 176, 0.2)' };

export const readableLanguagesNames: Record<ELocale, string> = {
  [ELocale.EN_US]: 'English (US)',
  [ELocale.EN_GB]: 'English (GB)',
  [ELocale.DE_DE]: 'Deutsch',
  [ELocale.FR_FR]: 'Français',
  [ELocale.IT_IT]: 'Italiano',
  [ELocale.PL_PL]: 'Polski',
  [ELocale.CS_CZ]: 'Čeština',
  [ELocale.SK_SK]: 'Slovenčina',
  [ELocale.NL_NL]: 'Dutch',
  [ELocale.NN_NO]: 'Norsk',
  [ELocale.ES_ES]: 'Español',
  [ELocale.SV_SE]: 'Svenska',
  [ELocale.DA_DK]: 'Dansk',
};

export const defaultLanguageOption = { value: locale, label: readableLanguagesNames[locale] };

export type ResortQueryOpenStatus = 'open' | 'closed' | '';

export const languageOptions = options.map((i) => ({ value: i.value, label: readableLanguagesNames[i.value] }));
