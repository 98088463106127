import {
  useRef, useCallback,
} from 'react';

import { IS_SSR } from 'ots-constants';
import { getPrebid } from 'prebidConfig';
import { OneTrustCategories } from 'onetrust/constants';

import { Diagnostics } from 'util/diagnostics';
import { useOneTrustInsertAvailableByStatus } from 'util/useOneTrustInsertAvailableByStatus';

import { getLocale } from 'i18n/locale';
import { isPrebidDisabled } from 'googlead/components/AdUnitContext/utils';
import { IOneTrustCookie } from 'onetrust/types';

export const GPT_SCRIPT_SRC = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

export const GPTScriptsWaitForOneTrust = () => {
  const scriptPlaceRef = useRef<HTMLDivElement>(null);

  const oneTrustCallback = useCallback((preparedOneTrust:typeof OneTrust, oneTrustCookie?:IOneTrustCookie) => {
    if (preparedOneTrust && !IS_SSR && !scriptPlaceRef?.current?.innerHTML) {
      Diagnostics.message({
        message: 'OneTrust Active. Adding GPT and prebid scripts with no delay.',
        tag: 'gpt',
        data: { categories: oneTrustCookie?.groups },
      });
      if (!scriptPlaceRef.current?.innerHTML) {
        Diagnostics.message({
          message: 'Adding GPT and prebid now',
          tag: 'gpt',
          data: { categories: oneTrustCookie?.groups },
        });
        preparedOneTrust.InsertScript(
          GPT_SCRIPT_SRC,
          'GPTScripts',
          () => {
          },
          {},
          OneTrustCategories.TargetingCookies,
          false,
        );
        if (!isPrebidDisabled()) {
          preparedOneTrust.InsertScript(
            getPrebid(),
            'GPTScripts',
            () => {
            },
            {},
            OneTrustCategories.TargetingCookies,
            false,
          );
        } else {
          Diagnostics.message({
            message: `Avoid prebid in US locale in test mode. [Current locale is ${getLocale()}]`,
            tag: 'ad prebid',
          });
        }
      } else {
        Diagnostics.message({ message: 'GPT and prebid already placed, not inserting again', tag: 'gpt' });
      }
    }
  }, []);

  useOneTrustInsertAvailableByStatus(oneTrustCallback);

  return (
    <div
      id="GPTScripts"
      ref={scriptPlaceRef}
    />
  );
};
