export const GTM_DATA_LAYER_NAME: string = 'dataLayer';

export const pushDataLayer = (l: string = GTM_DATA_LAYER_NAME, vars: Record<string, string | number | undefined>) => {
  (global as any)[l] = (global as any)[l] || [];
  (global as any)[GTM_DATA_LAYER_NAME].push({ event: 'otsPageview', ...vars });
};

export const setVar = (variable: string, value: string | number | undefined) => {
  (global as any)[GTM_DATA_LAYER_NAME].push({
    [variable]: value,
  });
};
export const setVarSet = (vars: Record<string, string | number | undefined>) => {
  (global as any)[GTM_DATA_LAYER_NAME].push(vars);
};

export const event = (action: string, category: string, label: string, params?: Object) => {
  (global as any)[GTM_DATA_LAYER_NAME].push({
    event: action,
    category,
    label,
    ...(!!params && Object.getOwnPropertyNames(params).length ? params : {}),
  });
};

export const pageview = (url: string, title: string = document.title, variables: object = {}) => {
  (global as any)[GTM_DATA_LAYER_NAME].push({
    event: 'page_view',
    page: {
      url,
      title,
    },
    ...variables,
  });
};

export const gtm = {
  event,
  pageview,
};
