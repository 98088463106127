import { IRegionShort } from 'api/region/types';
import { IResortPathInfo } from 'api/resort/types';
import { WidgetResort } from 'components/widget/types';
import { getNewsPrefix } from 'news-translations';
import { IGlobalUrls, IRegionUrls, IResortUrls } from 'portalConfig';
import { getPortalLinks } from 'util/getPortalConfig';
import { getPortalUrlTranslations } from 'util/urlTranslations';

export const rsUrl = (url: IResortUrls) => getPortalUrlTranslations().resort[url];
export const rgUrl = (url: IRegionUrls) => getPortalUrlTranslations().region[url];
export const glUrl = (url: IGlobalUrls) => getPortalUrlTranslations().global[url];

export const normalizeDomain = (domain: string) => {
  if (domain.startsWith('https://')) {
    return domain;
  }
  return `https://${domain}`;
};

export const resortUrl = (resort: IResortPathInfo | WidgetResort) => {
  if (resort.domain) {
    return `${normalizeDomain(resort.domain)}/${resort.region}/${resort.slug}/`;
  }
  return `/${resort.region}/${resort.slug}/`;
};
export const regionUrl = (region: IRegionShort | IResortPathInfo) => {
  if (region.domain) {
    return `${normalizeDomain(region.domain)}/${region.slug}/`;
  }
  return `/${region.slug}/`;
};
export const getOrigin = () => (typeof window !== 'undefined' && window.location.origin
  ? window.location.origin
  : ''
);

export const Routes = {
  root: '/',
  snowReports: () => `/${glUrl('skireport')}`,
  tripPlanning: () => `/${glUrl('ski-resorts')}`,
  nearbyResorts: () => `/${glUrl('nearby-resorts')}`,
  magazine: `/${getNewsPrefix()}/`,
  privacyPolicy: `/${getNewsPrefix()}${getPortalLinks().privacy}`,
  weather: `/${getNewsPrefix()}${getPortalLinks().weather}`,
  tos: `/${getNewsPrefix()}${getPortalLinks().tos}`,
  sitemap: '/sitemap',
  impressum: `/${getNewsPrefix()}${getPortalLinks().impressum}`,
  advertise: getPortalLinks().advertise,
  contactOTS: getPortalLinks().contact,
  newsAmbassadors: (translatedUrl: string) => `/${getNewsPrefix()}/${translatedUrl.toLowerCase()}/`,
  newsletter: (translatedUrl: string) => `/${getNewsPrefix()}/${translatedUrl.toLowerCase()}/`,

  resortSnowReport: (resort: IResortPathInfo | WidgetResort) => `${resortUrl(resort)}${rsUrl('skireport')}`,
  resortWeather: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('weather')}`,
  resortSnowfall: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('historical-snowfall')}`,
  resortCams: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('cams')}`,
  resortFHReports: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('firsthand')}`,
  resortTripPlanningOverview: (resort: IResortPathInfo | WidgetResort) => `${resortUrl(resort)}${rsUrl('ski-resort')}`,
  resortTripPlanningReviews: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('reviews')}`,
  resortTripPlanningLodging: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('lodging')}`,
  resortTripPlanningTrailMap: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('trailmap')}`,
  resortLiftTickets: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('lift-tickets')}`,
  resortSkiRentals: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('skirentals')}`,
  resortBookTrip: (resort: IResortPathInfo) => `${resortUrl(resort)}${rsUrl('book-trip')}`,

  regionSnowReport: (region: IRegionShort | IResortPathInfo) => `${regionUrl(region)}${rgUrl('skireport')}`,
  regionTripPlanningOverview: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('ski-resorts')}`,
  regionOpenResorts: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('open-resorts')}`,
  regionProjectedClosing: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('projected-closing')}`,
  regionProjectedOpenings: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('projected-openings')}`,
  regionLiftTickets: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('lift-tickets')}`,
  regionLodging: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('lodging')}`,
  regionCams: (region: IRegionShort) => `${regionUrl(region)}${rgUrl('cams')}`,
};
