import { useEffect, useRef } from 'react';
import { Diagnostics } from 'util/diagnostics';

import {
  insertFacebookPixelCode,
  initTracking,
  addFacebookPixelCodeFallback,
  checkIsFCPIDProvided,
} from 'util/facebookAnalytics/utils';
import { ONETRUST_PREFIX, OneTrustCategories } from 'onetrust/constants';
import { IS_SSR, isProductionServer } from 'ots-constants';
import { useOneTrustInsertAvailableByStatus } from 'util/useOneTrustInsertAvailableByStatus';
import { useServerOneTrustCookieGroups } from 'settings/SettingsContext';
import { IOneTrustCookieGroups } from 'onetrust/types';

const FPC_DEBOUNCE_TIME = 0;
const FACEBOOK_SCRIPT_SRC = 'https://connect.facebook.net/en_US/fbevents.js';

// Note: ensure both of this components are mounted and synced
// FPCHeaderWaitForOneTrust goes to App.tsx and loads GPT dynamically on OneTrust approval
// FPCHeaderPreApproved goes to document.tsx and loads GPT on server render if cookie is already permitted
export const FPCHeaderPreApproved = ({ preAllowedGroups }:{ preAllowedGroups: IOneTrustCookieGroups }) => {
  const isTargetingPreApproved = preAllowedGroups?.[OneTrustCategories.TargetingCookies];
  if (!isTargetingPreApproved) {
    Diagnostics.debug('[FPCHeaderPreApproved] Skipped, ensure you had [FPCHeaderWaitForOneTrust] fired');
    return null;
  }
  Diagnostics.debug('[FPCHeaderPreApproved] fired');

  if (!process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CODE_ID) {
    return null;
  }

  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          !function (f, b, e, v, n, t, s) {
              if (f.fbq) return;
              n = f.fbq = function () {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
              };
              if (!f._fbq) f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = !0;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s)
          }(window, document, 'script', '${FACEBOOK_SCRIPT_SRC}');
          
          fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CODE_ID}');
          fbq('track', 'PageView');
        `,
      }}
      />
    </>
  );
};

// Note: ensure both of this components are mounted and synced
// FPCHeaderWaitForOneTrust goes to App.tsx and loads GPT dynamically on OneTrust approval
// FPCHeaderPreApproved goes to document.tsx and loads GPT on server render if cookie is already permitted
export const FPCHeaderWaitForOneTrust = () => {
  const preAllowedGroups = useServerOneTrustCookieGroups();
  const isTargetingPreApproved = preAllowedGroups?.[OneTrustCategories.TargetingCookies];
  if (isTargetingPreApproved) {
    Diagnostics.debug('[FPCHeaderWaitForOneTrust] Skipped, ensure you had [FPCHeaderPreApproved] fired');
  } else {
    Diagnostics.debug('[FPCHeaderWaitForOneTrust] fired');
  }

  const scriptPlaceRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    addFacebookPixelCodeFallback(window);
  }, []);

  useOneTrustInsertAvailableByStatus((oneTrust) => {
    if (!isTargetingPreApproved && oneTrust && !IS_SSR && checkIsFCPIDProvided() && !scriptPlaceRef?.current?.innerHTML) {
      setTimeout(() => {
        if (!isProductionServer) {
          Diagnostics.debug('OneTrust.InsertScript', 'FPCHeader');
        }
        Diagnostics.message({ tag: 'FPCHeader', message: 'fired before insertFacebookPixelCode' });
        insertFacebookPixelCode(
          oneTrust!,
          FACEBOOK_SCRIPT_SRC,
          'FPC-with-scripts',
        );
        initTracking();
      }, FPC_DEBOUNCE_TIME);
    }
  });

  return (
    <div
      id="FPC-with-scripts"
      ref={scriptPlaceRef}
    />
  );
};

export const FPCNoScript = () => (
  <noscript>
    <img
      height="1"
      width="1"
      alt="facebook-pixel"
      className={`${ONETRUST_PREFIX}${OneTrustCategories.TargetingCookies}`}
      style={{ display: 'none' }}
      src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CODE_ID}&ev=PageView&noscript=1`}
    />
  </noscript>
);
