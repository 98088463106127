import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { ENV } from 'ots-constants';

export const fallback = { fileName: 'prebid' };

const prebidConfig: Record<ELocale, { fileName: string }> = {
  [ELocale.NL_NL]: {
    fileName: 'prebid_green',
  },
  [ELocale.FR_FR]: {
    fileName: 'prebid_green',
  },
  [ELocale.CS_CZ]: {
    fileName: 'prebid_czech',
  },
  [ELocale.DA_DK]: {
    fileName: 'prebid_green',
  },
  [ELocale.DE_DE]: {
    fileName: 'prebid_dach',
  },
  [ELocale.ES_ES]: {
    fileName: 'prebid_green',
  },
  [ELocale.FR_FR]: {
    fileName: 'prebid_fr',
  },
  [ELocale.IT_IT]: {
    fileName: 'prebid_italy',
  },
  [ELocale.NL_NL]: {
    fileName: 'prebid_green',
  },
  [ELocale.NN_NO]: {
    fileName: 'prebid_norway',
  },
  [ELocale.DE_DE]: {
    fileName: 'prebid_dach',
  },
  [ELocale.PL_PL]: {
    fileName: 'prebid_poland',
  },
  [ELocale.SK_SK]: {
    fileName: 'prebid_green',
  },
  [ELocale.SV_SE]: {
    fileName: 'prebid_slovakia',
  },
  [ELocale.EN_GB]: {
    fileName: 'prebid_uk',
  },
  [ELocale.EN_US]: {
    fileName: 'prebid_us',
  },
};

export const getPrebid = () => {
  const locale = getLocale() as ELocale;
  const { fileName } = prebidConfig[locale] || fallback;
  const domainFallback = (ENV === 'production') ? 'https://prebid.onthesnow.com' : 'https://prebid-test.onthesnow.com';
  const domain = process.env.NEXT_PUBLIC_PREBID_DOMAIN || domainFallback;
  return `${domain}/${fileName}.js?${process.env.VERSION}`;
};
