import { ONETRUST_COOKIE } from 'onetrust/constants';
import { IOneTrustCookie } from 'onetrust/types';
import { IS_SSR } from 'ots-constants';
import { Diagnostics } from 'util/diagnostics';

let otCookie: IOneTrustCookie | undefined;
let otReady: boolean = false;
let onReadyCallback: () => void;

export type PropsWithOneTrustCookieInfo = {
  OneTrustAllowed: IOneTrustCookie['groups'];
};

export const isPropsWithOneTrustCookieInfo = (props: any): props is PropsWithOneTrustCookieInfo => !!props?.OneTrustAllowed;

export const parseOTSCookieFromString = (cookieValue: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, ...rest] = cookieValue.trim().split('=');
  const valToParse = rest.join('=').split('&');
  const map: { [k: string]: string } = {};
  valToParse.forEach((v) => {
    const [vv, ...values] = v.split('=');
    map[vv] = decodeURIComponent(values.join('='));
  });

  const parsedOTCookie: IOneTrustCookie = {
    ...map as any,
    AwaitingReconsent: map.AwaitingReconsent === 'true',
    isIABGlobal: map.isIABGlobal === 'true',
    groups: (map.groups ?? '')
      .split(',')
      .map((v) => [v.split(':')[0], v.split(':')[1] === '1'])
      .filter((h) => !!h[0])
      .reduce((m: Record<string, boolean>, [group, bool]) => {
        // eslint-disable-next-line no-param-reassign
        m[`${group}`] = !!bool;
        return m;
      }, {}),
    hosts: (map.hosts ?? '')
      .split(',')
      .map((v) => [v.split(':')[0], v.split(':')[1] === '1'])
      .filter((h) => !!h[0]),
  };

  return parsedOTCookie;
};

export const parseOTCookie = (ready: boolean = false) => {
  otReady = otReady || ready;
  if (IS_SSR) {
    return undefined;
  }
  const cookies = (document?.cookie || '');
  const cookieValue = (cookies.split(';') || []).find((row) => (row || '').trim().startsWith(`${ONETRUST_COOKIE}=`));
  if (cookieValue) {
    otCookie = parseOTSCookieFromString(cookieValue);
    Diagnostics.message({
      tag: 'parseOTCookie',
      message: 'Cookie was parsed',
      data: otCookie,
    });

    if (ready) {
      onReadyCallback();
    }
    // we had loaded cookie that means it is ready
    otReady = true;
    return otCookie;
  }
  otCookie = undefined;
  Diagnostics.message({
    tag: 'parseOTCookie',
    message: 'cookie not found',
  });

  if (ready) {
    onReadyCallback();
  }
  return undefined;
};

export const setOTCookieOnReady = (fn: () => void) => {
  onReadyCallback = fn;
};

export const getOTCookie = () => otCookie;
export const getOTCookieReady = () => otReady;

(global as any).parseOTCookie = parseOTCookie;
