import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCountry, getLang, getLocale } from 'i18n/locale';

import { pushDataLayer, gtm, GTM_DATA_LAYER_NAME } from 'gtm/gtm';
import { ViewContentParamsType } from 'util/analytics/types';
import { IResortPathInfo } from 'api/resort/types';
import { Diagnostics } from 'util/diagnostics';
import { AB_VARIATION_NAME, IS_SSR } from 'ots-constants';

export enum SubscriberDataEvent {
  PlayVideo = 'Play Video',
  HomePageClick = 'Home Page Click',
  PhoneClick = 'Phone Click',
  SocialClick = 'Social Click',
  LiftAccessNavigation = 'Lift Access Navigation',
  RentalsWidget = 'Rentals Widget',
  LessonsWidget = 'Lessons Widget',
}

export enum LodgingDataEvent {
  LodgingSearch = 'Lodging Search',
  SkiRentalSearch = 'Ski Rental Search',
  ViewOffer = 'View Offer',
  SkiRentalShop = 'View Ski Rental Offer',
}

export enum PartnershipDataEvent {
  CarRentalClick = 'Car Rental click',
  FlightsClick = 'Flights click',
}

let prevPageView: string = '';
let prevKey: string = '';
const instance = Math.random();

export const setPageVariables = (context: IAnalyticsContext) => {
  const vars: Record<string, string | number | undefined> = {};
  const key = `${context.templateId} resort:${context.resortEnName} region:${context.regionEnName}`;

  vars.Market = getLocale();
  vars['Template ID'] = context.templateId;
  vars.templateName = context.templateName;
  vars.resortName = context.resortEnName;
  vars.resortId = context.resortId;
  vars.regionName = context.regionEnName;
  vars.regionId = context.regionId;
  vars.resortCountry = context.country;
  vars.siteLanguage = getLang();
  vars.siteCountry = getCountry();
  vars.abVariation = AB_VARIATION_NAME;

  if (prevKey !== key) {
    pushDataLayer(GTM_DATA_LAYER_NAME, vars);
    console.log('[setPageVariables]', key);
    prevKey = key;
  } else if (!IS_SSR) {
    Diagnostics.debug('Deduplicated setting vars for', context, instance);
  }
};

/**
 * @deprecated Using pageview directly is deprecated, GTM is configured to register pageview by itself
 */
export const pageView = (context: IAnalyticsContext, url: string) => {
  setPageVariables(context);

  gtm.pageview(url);

  console.warn('Using pageview directly is deprecated, GTM is configured to register pageview by itself. Track this usage and remove it');
};

export const subcriberEvent = (
  context: IAnalyticsContext,
  action: SubscriberDataEvent,
  data: { url?: string, resort?: IResortPathInfo },
) => {
  const eventCategory = 'Subscriber Data';
  const label = [context.templateId, data.resort?.title_original || context.resortEnName, data.url].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

export const logdingEvent = (
  context: IAnalyticsContext,
  action: LodgingDataEvent,
  data: { hotelName?: string, checkIn?: string, checkOut?: string, adults?: number, children?: number, skiRentalName?: string },
) => {
  const eventCategory = 'Travel Widget';
  let labelParts = [context.resortEnName, data.hotelName];
  if (action === LodgingDataEvent.LodgingSearch) {
    labelParts = [context.resortEnName, context.templateId, data.checkIn, data.checkOut];
  }
  const label = labelParts.filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

export const partnershipEvent = (context: IAnalyticsContext, action: PartnershipDataEvent) => {
  const eventCategory = 'Travel Widget';
  const serviceType = action.replace('click', '').trim();
  const labelParts = [context.resortEnName, context.templateId, serviceType];
  const label = labelParts.filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

export const skiRentalSearchEvent = (
  context: IAnalyticsContext,
) => {
  const eventCategory = 'Ski Rental search';
  const action = 'Ski Rental click';
  const labelParts = [context.resortEnName, 'Ski Rental search'];
  const label = labelParts.filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

export const skiRentalViewDeal = (
  context: IAnalyticsContext,
  shopId: string,
) => {
  const eventCategory = 'View deal';
  const action = 'Ski Rental shop click';
  const labelParts = [context.resortEnName, shopId, 'Rental View Deal'];
  const label = labelParts.filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

/**
 * Some clicks and Search Box Displays
 */
export const searchBox = (context: IAnalyticsContext) => {
  const eventCategory = 'Global Search';
  const action = 'Search Box';

  gtm.event(action, eventCategory, context.templateId);
};

/**
 * Change sorting event
 */
export const sortEvent = (context: IAnalyticsContext, sortBy: string) => {
  const eventCategory = 'Page Interactions';
  const action = 'Sorting';

  gtm.event(action, eventCategory, `${context.templateId} ${sortBy}`);
};

/**
 * When user clicks on the search result, it will pass the search term variable to Google Analytics.
 */
export const searchClick = (context: IAnalyticsContext, { query, url }: { query: string, url: string }) => {
  const eventCategory = 'Global Search';
  const action = 'Search Click';
  const label = [query, context.templateId, url].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

export const notFound = (context: IAnalyticsContext, { url }: { url: string }) => {
  const eventCategory = 'Not Found';
  const action = 'Not Found';
  const label = [url].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

/**
 * When a user clicks to view the additional content an Event is triggered.
 */
export const viewContent = (context: IAnalyticsContext, { content, ...params }: ViewContentParamsType) => {
  const eventCategory = 'Page Interactions';
  const action = 'View Content';
  const label = [content, context.templateId].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label, params);
};

/**
 * When a user clicks to view the additional content an Event is triggered.
 */
export const reviewCategory = (context: IAnalyticsContext, { content }: {
  content: string
}) => {
  const eventCategory = 'Page Interactions';
  const action = 'Review Categories';
  const label = [content, context.templateId].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

/**
 * When a user clicks to view the additional content an Event is triggered.
 */
export const sortContent = (context: IAnalyticsContext, { content }: {
  content: 'Highest Rated' | 'Name',
}) => {
  const eventCategory = 'Page Interactions';
  const action = 'Sort Content';
  const label = [content, context.templateId].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

/**
 * When a user clicks to view the additional content an Event is triggered.
 */
export const expandImage = (context: IAnalyticsContext, { content }: {
  content: 'Trail Map' | 'Google Map'
}) => {
  const eventCategory = 'Page Interactions';
  const action = 'Expand Image';
  const label = [content, context.templateId].filter((i) => !!i).join('|');

  gtm.event(action, eventCategory, label);
};

export const Analytics = {
  pageView,
  subcriberEvent,
  logdingEvent,
  expandImage,
  reviewCategory,
  sortContent,
  viewContent,
  searchBox,
  searchClick,
  sortEvent,
  notFound,
  skiRentalViewDeal,
  skiRentalSearchEvent,
  partnershipEvent,
};

/**
 * @deprecated Using pageview directly is deprecated, GTM is configured to register pageview by itself
 */
export const usePageView = (context: IAnalyticsContext) => {
  const route = useRouter();
  useEffect(() => {
    if (prevPageView !== route.asPath) {
      console.log('[page_view] Triggering page view from', prevPageView, ' to ', route.asPath, ' instance code', instance);
      prevPageView = route.asPath;
      Analytics.pageView(context, route.asPath);
    } else {
      console.warn('[page_view] Deduplicated pageview', route.asPath, ' instance code', instance);
    }
  }, [route.asPath]);
};

export interface IAnalyticsContext {
  /**
   * English(!) name for resort. Needs to be English specifically to be consistent in Analytics
   */
  resortEnName?: string;
  resortId?: number;
  regionEnName?: string;
  regionId?: number;
  country?: string;
  templateId: string;
  templateName: string;
}

export const ReactAnalyticsContext = React.createContext<IAnalyticsContext | null>({} as any);

export const AnalyticsContext = (props: IAnalyticsContext & { children: React.ReactNode }) => {
  const { children, ...value } = props;
  return <ReactAnalyticsContext.Provider value={value}>{children}</ReactAnalyticsContext.Provider>;
};

export const useAnalyticsContext = () => {
  const context = useContext(ReactAnalyticsContext);
  if (!context) {
    throw new Error('Use useAnalyticsContext inside AnalyticsContext wrapper');
  }
  return context;
};

export const useAnalytics = () => {
  const context = useAnalyticsContext();
  return {
    expandImage: (data: {
      content: 'Trail Map'
      | 'Google Map'
    }) => Analytics.expandImage(context, data),
    sortContent: (data: {
      content: 'Highest Rated'
      | 'Name',
    }) => Analytics.sortContent(context, data),
    viewContent: (data: ViewContentParamsType) => Analytics.viewContent(context, data),
    reviewCategory: (data: {
      content: string
    }) => Analytics.reviewCategory(context, data),
    searchBox: () => Analytics.searchBox(context),
    sort: (sortBy: string) => Analytics.sortEvent(context, sortBy),
    searchClick: (data: { query: string, url: string }) => Analytics.searchClick(context, data),
    subcriberEvent: (action: SubscriberDataEvent, data: {
      url?: string,
      resort?: IResortPathInfo,
    }) => Analytics.subcriberEvent(context, action, data),
    logdingEvent: (
      action: LodgingDataEvent,
      data: { hotelName?: string, skiRentalName?: string, adults?: number, children?: number, checkIn?: string, checkOut?: string },
    ) => Analytics.logdingEvent(context, action, data),
    skiRentalSearchEvent: () => Analytics.skiRentalSearchEvent(context),
    skiRentalViewDeal: (shopId: string) => Analytics.skiRentalViewDeal(context, shopId),
    partnershipEvent: (action: PartnershipDataEvent) => Analytics.partnershipEvent(context, action),
  };
};
