import { getDefaultDistanceUnit, getDefaultTemperatureUnit } from 'i18n/locale';
import { DistanceType, TempType } from 'i18n/types';
import { getFromLocalStorage, setToLocalStorage } from 'utils/ls';

export const VAR_NAME_DIST = 'unit-preference-dist';
export const VAR_NAME_TEMP = 'unit-preference-temp';

export const saveDistanceUnitToLocalStorage = (unit: DistanceType) => {
  setToLocalStorage(VAR_NAME_DIST, unit);
};

export const saveTemperatureUnitToLocalStorage = (unit: TempType) => {
  setToLocalStorage(VAR_NAME_TEMP, unit);
};

export const initialiseDistanceUnit = () => {
  const storedDistanceUnit = getFromLocalStorage(VAR_NAME_DIST) as DistanceType;
  const defaultValue = getDefaultDistanceUnit();

  if (!storedDistanceUnit) {
    saveDistanceUnitToLocalStorage(defaultValue);
  }

  return storedDistanceUnit || defaultValue;
};

export const initialiseTemperatureUnit = () => {
  const storedTemperatureUnit = getFromLocalStorage(VAR_NAME_TEMP) as TempType;
  const defaultValue = getDefaultTemperatureUnit();

  if (!storedTemperatureUnit) {
    saveTemperatureUnitToLocalStorage(defaultValue);
  }

  return storedTemperatureUnit || defaultValue;
};
