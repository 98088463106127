import React, { useContext } from 'react';
import { AB_VARIATION_NAME, USE_LINKS_PREFETCH } from 'ots-constants';
import { IOneTrustCookieGroups } from 'onetrust/types';

export interface IGlobalSettings {
  /**
    * What param to use for next/link
    * true will transform to undefined, false to false, undefined to undefined, null to undefined
  */
  prefetch?: boolean,

  abVariationName?: string,

  /**
   * Coming from SSR wrapper and auto-populated
   */
  OneTrustAllowed?: IOneTrustCookieGroups,
}

export const defaultVal: IGlobalSettings = {
  prefetch: USE_LINKS_PREFETCH,
  abVariationName: AB_VARIATION_NAME,
};

export const SettingsContext = React.createContext<IGlobalSettings>(defaultVal);

export const useSettingsPrefetch = (): false | undefined => {
  const { prefetch } = useContext(SettingsContext);
  return (prefetch === false) ? false : undefined;
};
export const useServerOneTrustCookieGroups = (): IOneTrustCookieGroups | undefined => {
  const { OneTrustAllowed } = useContext(SettingsContext);
  return OneTrustAllowed;
};
