import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 5, // Cache everything for 5 mins by default
      retry: 0, // Dont retry data, axios does that
      staleTime: 1000 * 60 * 5, // Consider queries stale only after 5 mins by default
    },
  },
});

export default queryClient;
