import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';

export const newsPrefixTranslation: Record<ELocale, string> = {
  [ELocale.EN_US]: 'news',
  [ELocale.DE_DE]: 'news',
  [ELocale.EN_GB]: 'news',
  [ELocale.FR_FR]: 'actualites',
  [ELocale.IT_IT]: 'news',
  [ELocale.PL_PL]: 'wiadomosci',
  [ELocale.CS_CZ]: 'clanky',
  [ELocale.SK_SK]: 'clanky',
  [ELocale.NL_NL]: 'skinieuws',
  [ELocale.NN_NO]: 'nyheter',
  [ELocale.ES_ES]: 'noticias',
  [ELocale.SV_SE]: 'noticias',
  [ELocale.DA_DK]: 'nyheder',
};

export const getNewsPrefixForFuture = () => newsPrefixTranslation[getLocale() as ELocale];

export const getNewsPrefix = () => 'news';
