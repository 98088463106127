import { useOTStatus } from 'onetrust/useOTStatus';
import { useEffect, useRef, useState } from 'react';

export const useOptAnonWrapper = (onOneTrustReady?: (oneTrust: typeof OneTrust) => void) => {
  const status = useOTStatus();
  const executed = useRef<boolean>(false);
  const [oneTrust, setOneTrust] = useState<typeof OneTrust | undefined>(undefined);

  useEffect(() => {
    if (typeof OneTrust !== 'undefined' && !executed.current && status.ready) {
      executed.current = true;
      setOneTrust(OneTrust);
      if (onOneTrustReady) {
        onOneTrustReady(OneTrust);
      }
    }
  }, [status]);

  return oneTrust;
};
