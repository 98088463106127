export const ENV = process.env.NEXT_PUBLIC_ENV;
export const MEMCACHE_PORT = process.env.NEXT_PUBLIC_MEMCACHE_PORT;
export const BUILD_LOCALE = process.env.NEXT_PUBLIC_LANG;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const BUILD_DATE = process.env.BUILD_TIME;
export const IS_SSR = typeof window === 'undefined';
export const IS_NODE_ENV_DEVELOP = process.env.NODE_ENV !== 'production';
export const isProductionServer = ENV === 'production';

export const PRERENDER_DYNAMIC: boolean = process.env.PRERENDER_DYNAMIC === '1' || process.env.PRERENDER_DYNAMIC === 'true';
export const WEATHER_PAGE_SIZE = 50;
export const DEFAULT_REVALIDATE = 5 * 60; // 5 minutes

export const USE_LINKS_PREFETCH: boolean | undefined = undefined;

export const AB_VARIATION_NAME = process.env.NEXT_PUBLIC_AB_VARIATION_NAME || '';
