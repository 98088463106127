import { ONETRUST_PREFIX, OneTrustCategories } from 'onetrust/constants';

export const addFacebookPixelCodeFallback = (
  context: any,
  fallbackObj: any = {},
) => {
  if (context.fbq) return;
  // eslint-disable-next-line no-param-reassign
  fallbackObj = function getDefaultFBQ() {
    // @ts-ignore
    if (fallbackObj.callMethod) {
      // @ts-ignore
      // eslint-disable-next-line prefer-spread,prefer-rest-params
      fallbackObj.callMethod.apply(fallbackObj, arguments);
    } else {
      // @ts-ignore
      if (!fallbackObj.queue) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        fallbackObj.queue = [];
      }
      //  @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      fallbackObj.queue.push(arguments);
    }
  };
  // eslint-disable-next-line no-param-reassign
  fallbackObj.push = fallbackObj;
  // eslint-disable-next-line no-param-reassign
  fallbackObj.loaded = !0;
  // eslint-disable-next-line no-param-reassign
  fallbackObj.version = '2.0';
  // eslint-disable-next-line no-param-reassign
  fallbackObj.queue = [];

  context.fbq = fallbackObj;

  // eslint-disable-next-line no-underscore-dangle
  if (!context._fbq) {
    // eslint-disable-next-line no-underscore-dangle
    context._fbq = fallbackObj;
  }
};

export const insertFacebookPixelCode = (
  oneTrust: IOneTrust,
  src: string,
  selector: string = 'head',
) => {
  oneTrust?.InsertScript(
    src,
    selector,
    () => {},
    {},
    OneTrustCategories.TargetingCookies,
    true,
  );
};

export const insertFacebookPixelCodeIMG = (
  oneTrust: IOneTrust,
  src: string,
  selector: string = 'head',
) => {
  oneTrust.InsertHtml(
    `
      <noscript>
        <img
          height="1"
          width="1"
          alt="facebook-pixel"
          className="${ONETRUST_PREFIX}${OneTrustCategories.TargetingCookies}"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=419774891779343&ev=PageView&noscript=1"
        />
      </noscript>
    `,
    selector,
    null,
    null,
    OneTrustCategories.TargetingCookies,
    true,
  );
};

export const initTracking = () => {
  (window as any).fbq('init', process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CODE_ID);
  (window as any).fbq('track', 'PageView');
};

export const checkIsFCPIDProvided = () => {
  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CODE_ID) {
    return true;
  }

  console.log('Facebook pixel code id are not provided, connection will not be established');
  return false;
};
