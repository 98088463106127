import { ONETRUST_PREFIX, OneTrustCategories } from 'onetrust/constants';
import { Diagnostics } from 'util/diagnostics';
import { pushDataLayer, GTM_DATA_LAYER_NAME } from 'gtm/gtm';
import { IOneTrustCookieGroups } from 'onetrust/types';
import { IS_SSR } from 'ots-constants';

export const DEBUG_TAG = '[GTMHeader]';

export const GTMHeader = ({ preAllowedGroups }: { preAllowedGroups: IOneTrustCookieGroups }) => {
  const preAllowedTargeting = preAllowedGroups[OneTrustCategories.TargetingCookies];
  Diagnostics.debug(DEBUG_TAG, IS_SSR, preAllowedTargeting ? 'Targeting pre-allowed' : 'Targeting not pre-allowed');

  pushDataLayer(GTM_DATA_LAYER_NAME, {});
  return (
    <>
      <script
        type={preAllowedTargeting ? 'text/javascript' : 'text/plain'}
        async
        className={`${ONETRUST_PREFIX}${OneTrustCategories.TargetingCookies}`}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='${GTM_DATA_LAYER_NAME}'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','${GTM_DATA_LAYER_NAME}','${process.env.NEXT_PUBLIC_GOOGLE_TAG}');`,
        }}

      />
    </>
  );
};

export const GTMIframe = () => (
  <noscript>
    <iframe
      title="Google Tag Manager"
      src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG}`}
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
    />
  </noscript>
);
