import TimeAgo from 'javascript-time-ago';
import enLocale from 'javascript-time-ago/locale/en';

/**
 * 'javascript-time-ago/locale/alias' is overriden with proper locale inside webpack config
 */
// @ts-ignore
import aliasLocale from 'javascript-time-ago/locale/alias';
import { Settings } from 'luxon';
import { getLocale } from 'i18n/locale';

TimeAgo.addLocale(enLocale as any);
TimeAgo.addLocale(aliasLocale);

Settings.defaultLocale = getLocale();

if (getLocale() === 'nn-NO') {
  Settings.defaultLocale = 'NO';
}
