export type I18nKey = I18nKeyGenerated;
export type I18nNameSpace = I18nNameSpaceGenerated;

export type I18nBundles = Record<I18nNameSpace, { [k: string]: string | object }>;

export enum TempType {
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
}

export enum DistanceType {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum SlopeType {
  US = 'US',
  FR_PL = 'FRPL',
  DE_IT_CZ_SK = 'DEITCZSK',
  FR = 'FR',
  PL = 'PL',
  DE = 'DE',
  IT = 'IT',
  CZ = 'CZ',
  SK = 'SK',
  SV = 'SV',
  NO = 'NO',
  default = 'default',
}

export enum ECountry {
  US = 'US',
  DE = 'DE',
  GB = 'GB',
  FR = 'FR',
  IT = 'IT',
  PL = 'PL',
  CZ = 'CZ',
  SK = 'SK',
  NL = 'NL',
  NO = 'NO',
  ES = 'ES',
  SE = 'SE',
  DK = 'DK',
}

export type ISlopes = {
  slopType: string,
  slopesColors: ISlopesColors,
};

export type ISlopesColors = {
  beginner?: string,
  intermediate?: string,
  advanced?: string,
  expert?: string,
};
