import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { portalConfig } from 'portalConfig';

export const getPortalConfig = () => portalConfig[getLocale() as ELocale];

export const getTypeformId = () => process.env.NEXT_PUBLIC_TYPEFORM_ID || (getPortalConfig() ? getPortalConfig().keys.typeformId : undefined);

export const getDFPTag = () => getPortalConfig().keys.dfpTag;

export const getPortalLinks = () => getPortalConfig().links;

export const getPortalSocial = () => getPortalConfig().social;

export const getPortalOptions = () => getPortalConfig().options;
