/* eslint-disable no-empty */
/* eslint-disable consistent-return */
export const getFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
  }
};

export const setToLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
  }
};
