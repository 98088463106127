import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import type { IOneTrustCookie } from 'onetrust/types';

import { parseOTCookie, setOTCookieOnReady } from 'onetrust/parseOTCookie';
import { Diagnostics } from 'util/diagnostics';

export type OneTrustCookieExtended = Partial<IOneTrustCookie> & { ready: boolean };

export const OTContext = React.createContext<OneTrustCookieExtended>({ ready: false });

export const OTContextProvider = ({ children }:{ children: React.ReactNode }) => {
  const [status, setStatus] = useState<IOneTrustCookie | undefined>(parseOTCookie());
  const [ready, setReady] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    setOTCookieOnReady(() => {
      // parseOTCookie function called by app-src/src/onetrust/OneTrustScript.tsx:46 every time OneTrust updated their cookie
      // and after parsing called this function as callback
      if (!ready) {
        setReady(true);
      }
      const updatedCookie = parseOTCookie();

      Diagnostics.message({
        message: 'OneTrust status updated',
        tag: 'OTContextProvider',
        data: {
          oneTrustCookie: updatedCookie,
          categories: updatedCookie?.groups,
        },
      });
      let isDisabledAnything = false;
      Object.keys(status?.groups || {}).forEach((key) => {
        if (status?.groups[key] && !updatedCookie?.groups[key]) {
          isDisabledAnything = true;
        }
      });

      if (isDisabledAnything) {
        router.reload();
      }

      setStatus(updatedCookie);
    });
  }, [status, ready]);

  useEffect(() => {
    const parseInterval: NodeJS.Timeout = setInterval(() => {
      const oneTrustCookie: IOneTrustCookie | undefined = parseOTCookie();
      // console.log((window as any).isOneTrustScriptLoaded)

      if (oneTrustCookie && (window as any).isOneTrustScriptLoaded) {
        setStatus(oneTrustCookie);
        setReady(true);

        Diagnostics.message({
          message: 'OneTrust cookie ready',
          tag: 'OTContextProvider',
          data: {
            isOneTrustAvailable: typeof OneTrust !== 'undefined',
            oneTrustCookie,
            categories: oneTrustCookie?.groups,
          },
        });

        clearInterval(parseInterval);
      }
    }, 150);

    return () => {
      if (parseInterval) {
        clearInterval(parseInterval);
      }
    };
  }, []);

  const extendedStatus: OneTrustCookieExtended = useMemo(() => ({ ...status, ready }), [status, ready]);

  return <OTContext.Provider value={extendedStatus}>{children}</OTContext.Provider>;
};

export const useOTStatus = () => useContext(OTContext);

export const useOTCategoryStatus = (category: string) => {
  const status = useOTStatus();
  if (!status || !status.groups) {
    return undefined;
  }
  return status.groups[category];
};
