import { useEffect, useState } from 'react';
import { IResortPathInfo, OpenFlag } from 'api/resort/types';
import {
  Nullable, GlobalPropsWithIsWidgetFlag, WidgetType, WidgetRenderItem, WidgetResortData,
} from 'components/widget/types';
import { localeConfig } from 'localeConfig';
import { ELocale } from 'i18n/localeEnum';
import { Routes } from 'routes';
import { IRegionShort } from 'api/region/types';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { SearchData, SearchOption } from './WidgetSearch';
import { ResortQueryOpenStatus, WIDGET_PAGE_PATH } from './constants';
import { LanguageOption, ResortFilterOptionValue } from './WidgetSettings';

export const isSingleItemGuard = (data: SearchData): data is SearchOption => Array.isArray(data) === false;
export const isResortGuard = (item: WidgetRenderItem): item is IResortPathInfo => !!(item as IResortPathInfo).region;
export const isRegionGuard = (item: WidgetRenderItem): item is IRegionShort => !!(item as IRegionShort).country;
export const isWidgetPageGuard = (props: any): props is GlobalPropsWithIsWidgetFlag => !!props?.isWidgetPage;

export const withDefaultValue = <DT>(defaultValue: DT) => <VT extends DT>(value: VT | undefined | null): DT => value ?? defaultValue;

export const useUtmData = (url: string) => {
  const [hostName, setHostName] = useState('unknown');

  useEffect(() => {
    const remoteUrl = document.referrer.replace(/^[^:]+:\/\//, '');
    // eslint-disable-next-line no-useless-escape
    const rmat2 = remoteUrl.match(/^([^\/]+)\/?/);
    const urlHost = window.location.hash.substring(1);
    const remoteHost = rmat2 ? rmat2[1] : urlHost || 'unknown';

    setHostName(remoteHost);
  }, []);

  const sign = /\?w/.test(url) ? '&' : '?';

  return `${url}${sign}utm_campaign=Affiliates&utm_medium=Widget&utm_source=${hostName}`;
};

const generateIframeParams = (
  widgetType: WidgetType,
  search: string,
  filter?: Nullable<ResortFilterOptionValue>,
): Record<string, string> => {
  let data = {};
  switch (true) {
    case widgetType === WidgetType.SINGLE_RESORT:
      data = { resort: search, type: 'snow' };
      break;
    case widgetType === WidgetType.LIST_OF_RESORTS:
      data = { resorts: search, type: 'list' };
      break;
    default:
      data = { regionId: search, type: 'list' };
  }
  if (filter) { data = { ...data, open: filter }; }

  return data;
};

export const generateWidgetURLs = (
  widgetType?: Nullable<WidgetType>,
  search?: Nullable<SearchData>,
  filter?: Nullable<ResortFilterOptionValue>,
  language?: Nullable<LanguageOption>,
): { url: string, poweredUrl: string } => {
  if (widgetType && search) {
    // const domain = localeConfig[language?.value as ELocale || ELocale.EN_US].websiteDomain;
    const domain = process.env.NODE_ENV === 'development'
      ? origin
      : localeConfig[language?.value as ELocale || ELocale.EN_US].websiteDomain;

    const searchString = isSingleItemGuard(search) ? search.value : search.map((i) => i.value).join(',');
    const { type, ...params } = generateIframeParams(widgetType, searchString, filter);
    const query = new URLSearchParams(params).toString();

    if (isSingleItemGuard(search)) {
      if (isResortGuard(search.item)) {
        return {
          url: `${domain}/${WIDGET_PAGE_PATH}/${type}?${query}`,
          poweredUrl: `${domain}${Routes.resortSnowReport(search.item)}`,
        };
      }
      if (isRegionGuard(search.item)) {
        return {
          url: `${domain}/${WIDGET_PAGE_PATH}/${type}?${query}`,
          poweredUrl: `${domain}${Routes.regionSnowReport(search.item)}`,
        };
      }
    }

    return {
      url: `${domain}/${WIDGET_PAGE_PATH}/${type}?${query}`,
      poweredUrl: domain,
    };
  }

  return { url: '', poweredUrl: '' };
};

export const generateIframeTemplate = (width?: number | null, height?: number | null, url?: string, poweredUrl?: string) => {
  const { t } = useOTSTranslation('common');
  if (!width || !height || !url) { return null; }

  return `<div class="ots-widget">
    <iframe
      width="${width}"
      height="${height}"
      title="OTS widget"
      src="${url}"
      border="0"
      frameborder="0"
    ></iframe>
    <p>
      <a href="${poweredUrl || Routes.root}" target="_blank">${t('widget.poweredBy')}</a>
    </p>
</div>`;
};

export const generateIdsArray = (data: string | string[] | undefined): string => {
  if (!data) { return ''; }

  return Array.isArray(data) ? data.join(',') : data;
};

export const defineWidgetType = (rest: { [key: string]: string | string[] | undefined; }) => [
  WidgetType.SINGLE_REGION,
  WidgetType.SINGLE_RESORT,
  WidgetType.LIST_OF_RESORTS,
].find((k) => rest[k]);

export const generateTemplateData = (widgetType?: WidgetType) => {
  switch (widgetType) {
    case WidgetType.SINGLE_RESORT: return { templateName: 'Widget Resort', templateId: '4.2.1' };
    case WidgetType.SINGLE_REGION:
    case WidgetType.LIST_OF_RESORTS:
      return { templateName: 'Widget Multi-Resort', templateId: '4.2.2' };
    default: return {};
  }
};

export const formatValues = (a?: string | number | null, b?: string | number | null): string => (a || b ? `${a ?? '-'} / ${b ?? '-'}` : '');

export const defineResortsStatuses = (data: { [key: string]: string | string[] | undefined; }): ResortQueryOpenStatus => {
  if (data?.open === 'all') return '';
  if (data?.open === 'all_closed') return 'closed';

  return 'open';
};

export const checkResortsForCloseStatus = (data: WidgetResortData[], status?: ResortQueryOpenStatus) => {
  const isOpenMode = status === 'open';

  return isOpenMode
    ? data.every((i) => i.status.openFlag === OpenFlag.Closed)
    : false;
};

export const showCloseLabel = (queryStatus: ResortQueryOpenStatus, resortOpenStatus?: OpenFlag) => {
  if (queryStatus === 'closed') return { showData: false, showCloseStatus: true };

  return { showData: resortOpenStatus !== OpenFlag.Closed, showCloseStatus: resortOpenStatus === OpenFlag.Closed };
};
