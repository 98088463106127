import { UnitContext } from 'components/UnitSwitch/context';
import {
  initialiseDistanceUnit,
  initialiseTemperatureUnit,
  saveDistanceUnitToLocalStorage,
  saveTemperatureUnitToLocalStorage,
} from 'components/UnitSwitch/storage';
import { getDefaultDistanceUnit, getDefaultTemperatureUnit } from 'i18n/locale';
import { DistanceType, TempType } from 'i18n/types';
import React, { useCallback, useEffect, useState } from 'react';

export const UnitSelectorProvider = ({ children }: { children: React.ReactNode }) => {
  const [distanceType, upsertDistanceType] = useState<DistanceType>(getDefaultDistanceUnit());
  const [temperatureType, upsertTemperatureType] = useState<TempType>(getDefaultTemperatureUnit());

  const setDistanceType = useCallback((unit: DistanceType) => {
    upsertDistanceType(unit);
    saveDistanceUnitToLocalStorage(unit);
  }, [upsertDistanceType]);

  const setTemperatureType = useCallback((unit: TempType) => {
    upsertTemperatureType(unit);
    saveTemperatureUnitToLocalStorage(unit);
  }, [upsertTemperatureType]);

  useEffect(() => {
    // refreshing data after mounting component on Front end after hydration
    const distance = initialiseDistanceUnit();
    const temperature = initialiseTemperatureUnit();

    upsertDistanceType(distance);
    upsertTemperatureType(temperature);
  }, []);

  return (
    <UnitContext.Provider value={{
      temperature: temperatureType,
      distance: distanceType,
      setDistanceType,
      setTemperatureType,
    }}
    >
      {children}
    </UnitContext.Provider>
  );
};
